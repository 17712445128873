<template>
  <div id="form" class="">
    <form action="" @submit.prevent="submitForm">
      <div class="row form_width py-5">
        <div class="col-12 mb-3">
          <input v-validate="'required|max:100'" class="form-control padding_y px-2 info" type="text" id="fullname" name="fullname" v-bind:placeholder="$t('recruitment.full_name')+'*'" v-model="full_name" :class="this.errors.has('fullname') && this.showErrors ? 'red-border' : ''">
          <span v-if="showErrors" class="error_message">{{ errors.first('fullname')? $t('required') :'' }}</span>
        </div>
        <div class="col-6 mb-3">
          <select name="section" id="section" class="padding_y px-2 w-100" v-model="section" @change="get_positions(section)" :class="this.section == $t('recruitment.section') + '*' && this.showErrors ? 'red-border' : ''">
            <option selected disabled>{{$t('recruitment.section')+'*'}}</option>
            <option v-for="( department, i) in departments" :key="i" :value="department.id">{{department.title}}</option>
          </select>
          <span v-if="this.section == $t('recruitment.section') + '*' && this.showErrors" class="error_message">{{$t('required')}}</span>
        </div>
        <div class="col-6 mb-3">
          <select name="position" id="position" class="padding_y px-2 w-100" :class="this.position == $t('recruitment.position') + '*' && this.showErrors ? 'red-border' : ''" v-model="position">
            <option selected disabled>{{$t('recruitment.position')+'*'}}</option>
            <option v-for="( position, i) in positions" :key="i" :value="position.id">{{ position.title }}</option>
            <!--                        <option v-for="( country, i) in countries" :key="i" :value="country.id">{{country.name}}</option>-->
          </select>
          <span v-if="this.position == $t('recruitment.position') + '*' && this.showErrors" class="error_message">{{ $t('required') }}</span>
        </div>
        <div class="col-6 mb-3">
          <input class="form-control padding_y px-2" v-validate="'required|numeric|min:10|max:15'" pattern="[0-9]*||[٠-٩]*" @keypress="preventNonNumericalInput($event)" type="text" id="mobile" name="mobile" v-bind:placeholder="$t('recruitment.mobile')+'*'" v-model="mobile" :class="this.errors.has('mobile') && this.showErrors ? 'red-border' : ''">
          <span v-if="showErrors && this.errors.first('mobile') != '' && this.errors.first('mobile') != 'The mobile field must be at least 10 characters'" class="error_message">{{ errors.first('mobile')? $t('required') :'' }}</span><span v-if="showErrors && this.errors.first('mobile') != '' && this.errors.first('mobile') == 'The mobile field must be at least 10 characters' " class="error_message">{{ errors.first('mobile')? $t('mobile') :'' }}</span>
        </div>
        <div class="col-6 mb-3">
          <input class="form-control padding_y px-2" v-validate="'numeric|min:4|max:15'" pattern="[0-9]*||[٠-٩]*" @keypress="preventNonNumericalInput($event)" type="text" id="phone" name="phone" v-bind:placeholder="$t('recruitment.phone')" v-model="phone" :class="this.errors.has('phone') && this.showErrors ? 'red-border' : ''">
          <span v-if="showErrors" class="error_message">{{ errors.first('phone')? $t('phone') :'' }}</span>
        </div>
        <div class="col-12 mb-3">
          <input class="form-control padding_y px-2" v-validate="'email|max:255'" type="text" id="email" name="email" v-bind:placeholder="$t('recruitment.email')" v-model="email" :class="this.errors.has('email') && this.showErrors ? 'red-border' : ''">
          <span v-if="showErrors && this.errors.first('email') != '' && this.errors.first('email') == 'The email field must be a valid email' " class="error_message">{{ errors.first('email')? $t('email') :'' }}</span>
        </div>
        <div class="col-12 mb-4">
          <div class="padding_y px-2 w-100 cv" :class="this.errors.has('fileUpload') && this.showErrors ? 'red-border' : ''" @click="chooseFiles()">
            <span class="file_name">{{$t('recruitment.cv')+'*'}}</span>
            <img src="../../assets/icons/cv.svg">
              <input v-validate="'required'" id="fileUpload" name="fileUpload" @change="saveFile($event)" accept=".pdf, .docx, .doc" type="file" hidden/>
          </div>
          <span v-if="showErrors" class="error_message">{{ errors.first('fileUpload')? $t('required') :'' }}</span>
        </div>
        <div class="col-12">
          <button type="submit" value="submit" class="request padding_y w-100" v-if="!submitSuccess">
            <span class="" v-if="!loading">
              {{$t('recruitment.request')}}
            </span>
            <span class="loading" v-if="loading">
              <span class="spinner-border text-light loader_style text-center" role="status">
              </span>
            </span>
          </button>
          <div class="success" v-if="submitSuccess">
            <!--            <font-awesome-icon class="icon" icon="check"/>-->
            <div class="success_message"> {{$t('submit_success')}} </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import $ from 'jquery'
import RecruitmentsRepository from '../../Repositories/RecruitmentRepository'
import DepartmentsRepository from '../../Repositories/DepartmentsRepository'

export default {
  name: 'Form',
  data () {
    return {
      full_name: '',
      mobile: '',
      phone: '',
      email: '',
      cv: null,
      section: this.$t('recruitment.section') + '*',
      position: this.$t('recruitment.position') + '*',
      showErrors: false,
      departments: [],
      positions: [],
      loading: false,
      submitSuccess: false,
      RecruitmentRepo: new RecruitmentsRepository(),
      DepartmentsRepo: new DepartmentsRepository()
    }
  },
  methods: {
    saveFile (e) {
      if (!window.FileReader) {
        alert('This browser does not support the FileReader API.')
      }
      // console.log('file value : ', e.target.files[0])
      this.cv = e.target.files[0]
      // console.log('file', this.cv)
    },
    chooseFiles () {
      document.getElementById('fileUpload').click()
      $(document).ready(function () {
        $('input[type="file"]').change(function (e) {
          var geekss = e.target.files[0].name
          $('.file_name').text(geekss)
        })
      })
    },
    preventNonNumericalInput (e) {
      e = e || window.$event
      var charCode = (typeof e.which === 'undefined') ? e.keyCode : e.which
      var charStr = String.fromCharCode(charCode)

      if (!charStr.match(/^[0-9]|[٠-٩]$/)) {
        e.preventDefault()
      }
    },
    async submitForm () {
      // console.log('fati', this.section)
      this.$validator.validate()
      this.showErrors = true
      // console.log('jj', this.errors.first('section'))
      if (!this.errors.has('fullname') && this.full_name !== '' && !this.errors.has('email') && !this.errors.has('mobile') && this.mobile !== '' && !this.errors.has('phone') && this.section !== this.$t('recruitment.section') + '*' && this.position !== this.$t('recruitment.position') + '*' && this.cv !== null) {
        this.loading = true
        const formData = new FormData()
        formData.append('full_name', this.full_name)
        formData.append('position', this.position)
        formData.append('phone', this.mobile)
        formData.append('email', this.email)
        formData.append('landline', this.phone)
        formData.append('cv', this.cv)
        const response = await this.RecruitmentRepo.create(formData)
        // console.log('fatima', response)
        if (response.status === 200 || response.status === true) {
          this.loading = false
          this.submitSuccess = true
        } else {
          // this.savingSuccessful = true
        }
      }
    },
    async get_departments () {
      const response = await this.DepartmentsRepo.get()
      this.departments = response.data.body
    },
    async get_positions (id) {
      // console.log('kk', this.section)
      const response = await this.DepartmentsRepo.getPositionsByDepartment(id)
      this.positions = response.data.body
      // console.log('tata', this.positions)
    }
  },
  created () {
    this.get_departments()
  },
  mounted () {
    if (localStorage.getItem('lang') === 'ar') {
      $('#form').css('direction', 'rtl')
      $('#form .cv').css('text-align', 'right')
      $('#form .cv img').css('float', 'left')
      $('#form select').css('background-position', '0.7rem center')
    } else {
      $('#form').css('direction', 'ltr')
      $('#form .cv').css('text-align', 'left')
      $('#form .cv img').css('float', 'right')
      $('#form select').css('background-position', 'calc(100% - 0.7rem) center')
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/scss/main";

#form .form_width {
  width: 55%;
  padding-right: 7.5rem;
  padding-left: 7.5rem;
  margin: auto;
  background: $form_background;
  box-shadow: 5px 5px 3px rgba(255,255,255,0.3), -4px -4px 3px rgba(255,255,255,0.4);
  border-radius: 10px;
  filter:  drop-shadow(15px 15px 20px rgba(0, 0, 0, 0.3));
  -webkit-filter:  drop-shadow(15px 15px 20px rgba(0, 0, 0, 0.3));
  -moz-filter:  drop-shadow(15px 15px 20px rgba(0, 0, 0, 0.3));
  -o-filter:  drop-shadow(15px 15px 20px rgba(0, 0, 0, 0.3));
}
#form input {
  color: $definitionblack;
  font-size: 10.56px;
}
#form select {
  //direction: ltr;
  -webkit-appearance: none;
  background: url("../../assets/icons/select.svg") no-repeat;
  background-size: 11px;
  //background-position:  calc(100% - 0.5rem) center;
  background-repeat: no-repeat;
}
#form select, #form .cv span {
  color: rgba(75,75,75,0.7);
  font-size: 10.56px;
}
#form .request {
  color: $primarywhite!important;
  font-size: 13.2px!important;
  background: $buttonorange;
  border: none!important;
  border-radius: 7px!important;
  box-shadow: 0px 10px 20px RGBA(224,89,43,0.6),  0px -1px 20px RGBA(224,89,43,0.3);
}
#form .padding_y {
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  border: 1px solid $definitionblack;
  border-radius: 4px;
}
#form .cv {
  cursor: pointer;
}
#form .cv img {
  width: 6.36px;
  height: 12.73px;
}
#form input::-webkit-outer-spin-button,
#form input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
#form input[type=number] {
  -moz-appearance: textfield;
}
#form form input:focus, #form form input button:focus{
  box-shadow: none;
  background: transparent;
}
#form input:-webkit-autofill, #form input:-webkit-autofill:active, form input:-webkit-autofill:focus{
  transition: background-color 5000s;
  color: rgba(75,75,75,0.7);
}
#form .red-border {
  border: 1px solid #cc0000 !important;
}
#form .error_message {
  color: #cc0000;
  font-size: 9.24px;
  float: right;
  padding: 0 10px;
}
#form .success_message {
  color: $buttonorange;
  font-size: 13.2px;
}
#form .spinner-border {
  width: 14px;
  height: 14px;
}
@media screen and (max-width: 480px) {
  #form .form_width {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  #form .form_width {
    width: 85%;
  }
}
@media screen and (min-width: 480px) and (max-width: 768px){
  #form .form_width {
    width: 80%;
  }
  #form .form_width {
    padding-right: 2rem;
    padding-left: 2rem;
  }
}
@media screen and (min-width: 768px) and (max-width: 992px){
  #form .form_width {
    width: 70%;
    padding-right: 6.5rem;
    padding-left: 6.5rem;
  }
}

@media screen and (max-width: 768px) {
  //#form input, #form select, #form .cv span {
  //  font-size: 2.8vmin;
  //}
  //#form .request {
  //  font-size: 3.2vmin!important;
  //}
  #form .cv img {
    float: left;
    //width: 3.5vmin;
    //height: 3.5vmin;
  }
  //#form .error_message {
  //  font-size: 2.4vmin;
  //}
  //#form .success_message {
  //  font-size: 3vmin;
  //}
  //#form .spinner-border {
  //  width: 0.8rem;
  //  height: 0.8rem;
  //}
}
@media screen  and (max-width: 992px) and (min-width: 768px) {
  //#form input, #form select, #form .cv span {
  //  font-size: 2vmin;
  //}
  //#form .request {
  //  font-size: 2.4vmin!important;
  //}
  #form .cv img {
    float: left;
    //width: 2.7vmin;
    //height: 2.7vmin;
  }
  //#form .error_message {
  //  font-size: 1.6vmin;
  //}
  //#form .success_message {
  //  color: $buttonorange;
  //  font-size: 2.2vmin;
  //}
  //#form .spinner-border {
  //  width: 1rem;
  //  height: 1rem;
  //}
}
</style>
