<template>
  <div id="recruitment" class="d-flex align-items-center justify-content-center">
    <div class="row">
      <div class="col-12 brand mb-4">
        <img src="../assets/Dama-logo-blue.svg">
      </div>
      <div class="col-12 title mb-4 mt-1 px-lg-0 px-md-3 px-5">
        <h3> {{$t('recruitment.title')}}</h3>
      </div>
      <div class="col-12">
        <Form/>
      </div>
    </div>
<!--    <router-link :to="'/'">-->
      <span @click="$router.go(-1)" class="close_btn"><img src="../assets/icons/close button.svg"></span>
<!--    </router-link>-->
  </div>
</template>

<script>
import Form from '../components/Recruitment/Form'
import $ from 'jquery'
export default {
  name: 'Recruitment',
  metaInfo: {
    title: 'طلب توظيف'
    // titleTemplate: '%s | شركة داما',
    // lang: 'en-US',
    // meta: [
    //   { charset: 'utf-8' },
    //   // { name: 'description', content: 'داما هي شركة وطنية رائدة متخصصة بإنتاج المجبول البيتوني ومواد البناء بجودة عالية ضمن المواصفات القياسية داخل العاصمة دمشق' },
    //   { name: 'viewport', content: 'width=device-width, initial-scale=1' }
    // ]
  },
  components: { Form },
  mounted () {
    if (localStorage.getItem('lang') === 'ar') {
      $('#recruitment .close_btn img').css('right', '4vh')
    } else {
      $('#recruitment .close_btn img').css('left', '4vh')
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/scss/main";
#recruitment {
  overflow: hidden;
  background: url("../assets/images/recruitment.png") #E2E2E2 no-repeat center center;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  //padding-top: 4rem;
  //padding-bottom: 6.5rem;
  position: relative;
  min-height: 100vh;
}
#recruitment .close_btn img {
  position: absolute;
  top: 6vh;
  //width: 4.282vmin;
  width: 25.66px;
  height: 19.08px;
  //height: 3.134vmin;
  cursor: pointer;
}
#recruitment .title h3 {
  color: $definitionblue;
  font-family: Messiri-Bold;
  font-size: 13.2px;
}
#recruitment .brand img {
  //height: 12.31vh;
  //width: 13.76vh;
  //height: 81.24px;
  //width: 90.81px;
  width: 161.04px;
  height: 60.72px;
}
@media screen and (max-width: 768px) {
  //#recruitment .title h3 {
  //  font-size: 3vmin;
  //}
  #recruitment {
    background: url("../assets/images/contact_rotate.png") no-repeat center center;
    background-size: cover;
  }
  //#recruitment .close_btn img {
  //  width: 5.282vmin;
  //  height: 4.134vmin;
  //}
}
@media screen  and (max-width: 992px) and (min-width: 768px) {
  //#recruitment .title h3 {
  //  font-size: 2.2vmin;
  //}
  //#recruitment .close_btn img {
  //  width: 4.482vmin;
  //  height: 3.334vmin;
  //}
}

</style>
