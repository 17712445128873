import CrudRepository from './CrudRepository'
import AxiosClient from './AxiosClient'

export default class DepartmentsRepository extends CrudRepository {
  constructor () {
    super('departments')
  }

  async getPositionsByDepartment (id) {
    return await AxiosClient.get(`${this.resource}/${id}/positions`)
  }
}
