<template>
  <header id="news_navbar">
    <div class="row">
      <nav class="navbar navbar-expand navbar-dark">
        <div class="container-fluid">
          <div class="col-6 d-flex justify-content-start align-items-center px-4">
            <a class="navbar-brand" href="#"><img src="../../assets/Dama-logo-white.svg" alt="Dama logo"></a>
          </div>
          <div class="col-6 d-flex justify-content-end align-items-center nav_title px-4">
           <span class="mx-3"> {{$t('news.nav_title')}} </span>
<!--            <router-link :to="'/'">-->
              <img @click="$router.go(-1)" src="../../assets/icons/news_nav_back.svg">
<!--            </router-link>-->
          </div>
        </div>
      </nav>
    </div>
  </header>
</template>

<script>

// import i18n from '../../i18n'
// import $ from 'jquery'
import $ from 'jquery'

export default {
  name: 'NewsNavbar',
  data () {
    return {
    }
  },
  methods: {
  },
  mounted () {
    if (localStorage.getItem('lang') === 'ar') {
      $('#news_navbar').css('direction', 'ltr')
    } else {
      $('#news_navbar').css('direction', 'rtl')
      $('#news_navbar .nav_title img').css('transform', 'rotate(180deg)')
    }
  }
}

</script>

<style scoped lang="scss">
@import "src/scss/main";
.disabled_lang {
  color: rgba(255,255,255,0.4)!important;
}
#news_navbar {
  //direction: rtl;
  position: fixed;
  z-index: 500000;
  width: 100%;
  background-color: rgba(0,0,0,0.5) !important;
  backdrop-filter: blur(11px) opacity(1);
  -webkit-backdrop-filter: blur(11px) opacity(1);
  -moz-backdrop-filter: blur(11px) opacity(1);
  -o-backdrop-filter: blur(11px) opacity(1);
}
#news_navbar .navbar-brand img {
  //height: 44.22px;
  //width: 133.36px;
  width: 125.3278px;
  height: 49.5202px;
}
#news_navbar .container-fluid {
  padding: 0;
}
#news_navbar .col-md-4, #news_navbar .col-md-8 {
  padding: 0;
}
#news_navbar .nav_title {
  font-family: Messiri-Bold;
  font-size: 16.5px;
}
#news_navbar .nav_title img {
  width: 21.66px;
  height: 14.08px;
  cursor: pointer;
}
@media screen and (max-width: 576px) {
  //#news_navbar .navbar-brand img {
  //  width: 17vh;
  //  min-width: 17vh;
  //  min-height: 5.5vh;
  //  height: 5.5vh;
  //}
}
@media screen and (max-width: 768px) and (min-width: 576px) {
  //#news_navbar .navbar-brand img {
  //  width: 18.5vh;
  //  min-width: 18.5vh;
  //}
}
@media screen and (max-width: 992px) and (min-width: 768px) {
  //#news_navbar .nav_title {
  //  font-size: 2.7vmin;
  //}
  //#news_navbar .nav_title img {
  //  width: 3.482vmin;
  //  height: 2.334vmin;
  //}
}
@media screen and (max-width: 768px) {
  //#news_navbar .nav_title {
  //  font-size: 3.5vmin;
  //}
  //#news_navbar .nav_title img {
  //  width: 4.282vmin;
  //  height: 3.134vmin;
  //}
}
</style>
