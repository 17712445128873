<template>
  <div id="news_single_page">
    <news-navbar/>
    <news-details/>
  </div>
</template>

<script>
import NewsNavbar from '../components/Shared/NewsNavbar'
import NewsDetails from '../components/News/NewsDetails'
export default {
  name: 'NewsSinglePage',
  components: { NewsDetails, NewsNavbar }
}
</script>

<style scoped>

</style>
