<template>
  <section id="services_details">
    <div class="slider">
      <VueSlickCarousel :arrows="false" :dots="false" :autoplay="true" v-if="card.images.length>0" ref="carousel" v-bind="settings">
        <!--        <div :class="'slide'+i" class="container-fluid" v-for="(item, i) in $t('carousel')" :key="i"></div>-->
        <div v-for="(img,i) in card.images" :key="i">
          <div class="slider_image" :style="{ 'background': 'url( ' + baseUrl + img.image + ') no-repeat center center'}" role="img" :aria-label="card.title"></div>
        </div>
      </VueSlickCarousel>
      <a class="prev d-flex justify-content-center align-items-center" v-on:click="prev">
        <img src="../../assets/icons/left_white.svg">
      </a>
      <a class="next d-flex justify-content-center align-items-center" v-on:click="next">
        <img src="../../assets/icons/right_white.svg">
      </a>
    </div>
    <div class="info">
      <div class="row mx-md-5 mx-4 py-5">
        <div class="col-12 pb-2">
          <!--          <div class="title pb-4"> {{$t('news.news[' + id + '].card_title')}}</div>-->
          <img :src="baseUrl + card.icon" :alt="card.title">
        </div>
        <div class="col-12">
          <!--          <div class="title pb-4"> {{$t('news.news[' + id + '].card_title')}}</div>-->
          <div class="title"> <h1 class="pb-3"> {{card.title}} </h1> </div>
        </div>
        <!--        <div class="col-12">-->
        <!--&lt;!&ndash;          <div class="description"> {{$t('news.news[' + id + '].card_description')}} </div>&ndash;&gt;-->
        <!--          <div class="description"> {{card.created_at}} </div>-->
        <!--        </div>-->
        <div class="col-12 py-4">
          <!--          <div class="body"> {{$t('news.news[' + id + '].body')}} </div>-->
          <p class="body"> {{card.description}} </p>
        </div>
      </div>
    </div>
  </section>

</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import $ from 'jquery'
import Constants from '../../Plugins/Constants'
import ServiceRepository from '../../Repositories/ServiceRepository'

export default {
  name: 'ServicesDetails',
  // metaInfo: {
  // },
  data () {
    return {
      title: 'Default value',
      id: 0,
      // results: [],
      // numPages: 0,
      // page: 1,
      // per_page: 4,
      card: {
        title: '',
        description: '',
        created_at: '',
        images: []
      },
      show: [],
      settings: {
        fade: true,
        // speed: 600
        speed: 500,
        autoPlay: true,
        autoplaySpeed: 3500,
        pauseOnHover: false
      },
      baseUrl: Constants.baseUrl,
      ServicesRepo: new ServiceRepository()
    }
  },
  metaInfo () {
    return {
      title: this.title
    }
  },
  components: { VueSlickCarousel },
  methods: {
    // async getPage (slideIndex) {
    //   // console.log('our carousel is ready', slideIndex)
    //   const x = await this.NewsRepo.getPage(this.page)
    //   // console.log('our carousel is ready', x)
    //   this.results = x.content
    //   // this.page ===+ 1
    //   // console.log('results-newsss', this.results)
    //   // console.log('results-images', '+ this.baseUrl + results[1].images[1].image +')
    // },
    async getSingleServices (id) {
      // console.log('kk', this.section)
      const response = await this.ServicesRepo.getSigleServiceById(id)
      this.card = response.data.body
      this.title = this.card.title
      // console.log('tata', this.card)
      // console.log('tata', typeof this.card.images)
    },
    prev () {
      this.$refs.carousel.prev()
    },
    next () {
      this.$refs.carousel.next()
    }
  },
  created () {
    // this.getPage(1)
    this.id = this.$route.params.newsId
    if (this.id !== 0) {
      this.getSingleServices(this.id)
    }
  },
  mounted () {
    // console.log('hi1', this.id)
    if (localStorage.getItem('lang') === 'ar') {
      $('#services_details').css('direction', 'rtl')
      // $('#services_details .title, #services_details .description').css('text-align', 'right')
    } else {
      $('#services_details').css('direction', 'ltr')
      // $('#services_details .title, #services_details .description').css('text-align', 'left')
    }
  }
}
</script>

<style lang="scss">
@import "src/scss/main";
#services_details {
  overflow: hidden;
  text-underline-position: under!important;
}
#services_details .slider {
  position: relative;
  height: 69vh;
  //height: 60vh;
  z-index: 300;
}
#services_details .slider_image {
  height: 69vh;
  //background: url("../../assets/images/carousel2.png") no-repeat center center;
  background-size: cover!important;
}
#services_details .slick-prev:before {
  content: url("../../assets/icons/left_service.svg");
  -webkit-transition: transform .2s ease-in-out;
  position: absolute;
  //bottom: -48vh;
  left: 3vh!important;
}
#services_details .slick-prev, #services_details .slick-next {
  z-index: 10000;
}
#services_details .slick-next:before {
  content: url("../../assets/icons/right_service.svg");
  -webkit-transition: transform .2s ease-in-out;
  position: absolute;
  //bottom: -48vh;
  right: 3vh!important;
}
#services_details .title h1 {
  color: $buttonorange;
  font-size: 21.12px;
  font-family: Messiri-Bold;
  border-bottom: 1.5px solid;
  //text-decoration: underline;
  //width: 40%;
}
//#services_details .description {
//  color: $definitionblue;
//  font-family: Messiri-SemiBold;
//  font-size: 2vmin;
//}
#services_details .body {
  color: $single_page_black;
  font-size: 14.52px;
  text-align: center;
}
#services_details .info {
  background: $lightGray;
  margin-top: -5vh;
  z-index: 400;
  position: absolute;
  //border: 4px solid rgba(255,255,255,0.4);
  //box-shadow: inset 2px 2px 5px 2px rgba(255,255,255,0.4);
  box-shadow: 2px 2px 1px rgba(255,255,255,0.4), -2px -2px 1px rgba(255,255,255,0.8);
  border-radius: 10px;
  filter:  drop-shadow(15px 15px 20px rgba(0, 0, 0, 0.3));
  -webkit-filter:  drop-shadow(15px 15px 20px rgba(0, 0, 0, 0.3));
  -moz-filter:  drop-shadow(15px 15px 20px rgba(0, 0, 0, 0.3));
  -o-filter:  drop-shadow(15px 15px 20px rgba(0, 0, 0, 0.3));
  width: 100%;
  min-height: 36vh;
}
#services_details .info img {
  width: 41.02px;
  height: 41.02px;
}
#services_details .prev {
  top: 40%;
  margin-top: 18.5px;
  left: 0px;
  position: absolute;
  width: 43.56px;
  height: 81.84px;
  //background-color: rgba(255,255,255,0.4) !important;
  //backdrop-filter: blur(11px) opacity(1);
  //-webkit-backdrop-filter: blur(11px) opacity(1);
  //-moz-backdrop-filter: blur(11px) opacity(1);
  //-o-backdrop-filter: blur(11px) opacity(1);
  background-color: rgba(0,0,0,0.2) !important;
  backdrop-filter: blur(11px) opacity(1);
  -webkit-backdrop-filter: blur(11px) opacity(1);
  -moz-backdrop-filter: blur(11px) opacity(1);
  -o-backdrop-filter: blur(11px) opacity(1);
  cursor: pointer;
}
#services_details .next {
  top: 40%;
  margin-top: 18.5px;
  right: 0;
  position: absolute;
  width: 43.56px;
  height: 81.84px;
  background-color: rgba(0,0,0,0.2) !important;
  backdrop-filter: blur(11px) opacity(1);
  -webkit-backdrop-filter: blur(11px) opacity(1);
  -moz-backdrop-filter: blur(11px) opacity(1);
  -o-backdrop-filter: blur(11px) opacity(1);
  cursor: pointer;
}
#services_details .next img, #services_details .prev img {
  width: 8.811px;
  height: 17.6px;
  //width: 13.35 vmin;
  //height: 26.7 vmin;
}
@media screen and (max-width: 768px) {
  //#services_details .title h1 {
  //  font-size: 5.2vmin;
  //}
  //#services_details .description {
  //  font-size: 3vmin;
  //}
  //#services_details .body {
  //  font-size: 3.2vmin;
  //}
  //#services_details .title {
  //  width: 80%;
  //}
  //#services_details .info img {
  //  width: 7.2vmin;
  //  height: 7.2vmin;
  //}
  //#services_details .prev, #services_details .next {
  //  width: 8.6vmin;
  //  height: 14.4vmin;
  //}
  //#services_details .next img, #services_details .prev img {
  //  width: 2.335vmin;
  //  height: 3.67vmin;
  //}
}
@media screen and (max-width: 480px) {
  #services_details .slider_image, #services_details .slider {
    height: 45vh;
  }
  #services_details .info {
    min-height: 60vh;
  }
}
@media screen and (min-width: 768px) and (max-width: 992px) {
  //#services_details .title h1 {
  //  font-size: 4.4vmin;
  //}
  //#services_details .description {
  //  font-size: 2.2vmin;
  //}
  //#services_details .body {
  //  font-size: 2.4vmin;
  //}
  //#services_details .title {
  //  width: 50%;
  //}
  //#services_details .info img {
  //  width: 6.4vmin;
  //  height: 6.4vmin;
  //}
  //#services_details .next img, #services_details .prev img {
  //  width: 2.335vmin;
  //  height: 3.67vmin;
  //}
  //#services_details .prev, #services_details .next {
  //  width: 7.6vmin;
  //  height: 13.4vmin;
  //}
}
</style>
