<template>
  <div id="services_single_page">
    <services-navbar/>
    <services-details/>
  </div>
</template>

<script>
import ServicesNavbar from '../components/Shared/ServicesNavbar'
import ServicesDetails from '../components/Services/ServicesDetails'
export default {
  name: 'ServicesSinglePage',
  components: { ServicesDetails, ServicesNavbar }
}
</script>

<style scoped>

</style>
